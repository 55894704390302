import React from "react";

//import { FaTelegramPlane, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';

import "./index.css";

const Index = () => {
  return (
    <>
      <h3>my name is</h3>
      <h1 className="header">Yuki Ona</h1>
      <p>I create furry art and can fullfill your imagination.</p>
      <hr />
      <p>
        I just recently started this page and it's in early development. My
        pictures and more content will follow soon.
      </p>
      <hr />
      <p>
        You can reach out to me at &nbsp;
        <a href="mailto:commissions@yuki-ona.de">commissions[at]yuki-ona.de</a>
        <br />I am always happy to get to know new people and their dreams.
      </p>
      <br />
      <br />
      <br />
      <p>
        have a nice day and see you soon,
        <br />
        your Yuki
      </p>
    </>
  );
};

export default Index;

// This project here is my little try to get a bit more independent and gain more time to do arts. Hopefully you can watch me and my furries in this process over the next years.

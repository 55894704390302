import { Outlet } from 'react-router-dom'

import './layout.css'
import Navigation from '../../components/navigationbar/navigation'

const Layout = () => {
  return (
    <>
      <Navigation />
      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default Layout

import React from 'react'

export default function Imprint() {
    return (
        <div style={{fontFamily: 'monospace'}}>
            <h1>Imprint</h1>
            <p>Informations according to <a href="https://www.gesetze-im-internet.de/tmg/__5.html">§5 TMG</a></p>
            <p>The page <a href="yuki-ona.de">Yuki-Ona.de</a> is operated and represented by Yuki Ona. Yuki Ona is my artist name and pseudonym.</p>
            <p><strong>Contact:</strong> <a href='mailto:info@yuki-ona.de'>info[at]yuki-ona.de</a></p>
            <br /><br />
            <h3>Disclaimer: </h3>

            <h4>Liability for contents</h4>
            <p>The contents of my site were created with the greatest care. However, I cannot assume any liability for the correctness, completeness and topicality of the contents. As a service provider, I am responsible for my own content on this site in accordance with §7 Para.1 of the German Telemedia Act (TMG) under the general laws. According to §8 to 10 TMG, however, I am not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information according to general laws remain unaffected by this. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If I become aware of any such infringements, I will remove this content immediately.</p>

            <h4>Liability for links</h4>
            <p>My offer contains links to external websites of third parties, on whose contents I have no influence. Therefore, I cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If I become aware of any infringements, I will remove such links immediately.</p>

            <h4>Copyright</h4>
            <p>The content and works on these pages created by the site operator and partners are subject to German copyright law. Duplication, processing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, please inform me accordingly. If I become aware of any infringements, I will remove such content immediately.</p>

            <h4>Data protection</h4>
            <p>Data protection is an important topic for me, which is why I offer this space on a separate page. Find everything about data protection under <a href="/privacy">Privacy</a>.</p>
        </div>
    )
}

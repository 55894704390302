import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FaBars, FaTimes, FaRegCopyright } from "react-icons/fa";

import "./navigation.css";

const Navigation = () => {
  const [extendedNavbar, toggleNavbar] = useState(false);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleNavbar(!extendedNavbar);
  };

  const toggle = (_: React.MouseEvent<HTMLAnchorElement>) => {
    toggleNavbar(!extendedNavbar);
  };

  return (
    <nav>
      <button className="navtoggle" onClick={handleToggle}>
        <p>{extendedNavbar ? <FaTimes /> : <FaBars />}</p>
      </button>
      <div className={`navmenu ${extendedNavbar ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/" onClick={toggle} className="navitem">
              {" "}
              Home{" "}
            </Link>
          </li>
          <li>Soon here will be more pages just for you</li>
        </ul>
        <footer>
          <Link to="/privacy" onClick={toggle} className="footeritem">
            {" "}
            Privacy{" "}
          </Link>
          <Link to="/imprint" onClick={toggle} className="footeritem">
            {" "}
            Imprint{" "}
          </Link>
          <p>
            <FaRegCopyright /> by Yuki Ona
          </p>
        </footer>
      </div>
    </nav>
  );
};

export default Navigation;

import React from 'react'
import { Link } from 'react-router-dom'

export default function Page404() {
  return (
    <>
      <h1>This Page coudn&apos;t be found</h1>
      <h2>Maybe you tried something new, that&apos;s not here for now.</h2>
      <h3>More content is coming soon. Come back soon to see more and changes.</h3>

      <div>
        <Link to='/' > Back to Home </Link>
      </div>
    </>
  )
}
